<template>
    <div>
      <div class="container">
        
        <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-md-12 mb-3">
            <img alt="image"
              class="img-fluid"
              style="
                box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
                border-radius: 5px;
              "
              src="../../../assets/Images/IJSREAT2021.jpg"
            />
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <img alt="image"
              class="img-fluid"
              style="
                box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
                border-radius: 5px;
              "
              src="../../../assets/Images/IJSREAT2022.jpg"
            />
          </div>
          <div class="col-lg-6 col-md-12 mb-3">
            <img alt="image"
              class="img-fluid"
              style="
                box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
                border-radius: 5px;
              "
              src="../../../assets/Images/IJSREAT2023.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  </style>